bovoApp.
  factory('AllocationControl', ['$http', function ($http) {
    return {
      all: function (data) {
        return $http.post('api/allocation-controls', data);
      },
      get: function (id) {
        return $http.get('api/allocation-control/' + id);
      }
    };
  }]);