bovoApp.controller('AllocationController', ['$scope', 'Allocation', '$location', '$routeParams', '$filter', '$translate', 'FileUploader','$rootScope',
  function ($scope, Allocation, $location, $routeParams, $filter, $translate, FileUploader,$rootScope) {
    $scope.deleted = [];

    $scope.all = function () {
      Allocation.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      Allocation.get(item.id).success(function (data, status, headers, config) {
        $('#allocationModal').modal('show');
        $scope.allocation = data.allocation;
        $scope.formData = data;
        $rootScope.gridOptionsComplex = {
					enableFiltering: true,
					showGridFooter: true,
					showColumnFooter: true,
					paginationPageSizes: [500, 1000, 2000],
					paginationPageSize: 500,
					enableGridMenu: true,
					enableSelectAll: true,
					exporterCsvFilename: 'KararListesi.csv',
					exporterPdfDefaultStyle: { fontSize: 9 },
					exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
					exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
					exporterPdfHeader: { text: "My Header", style: 'headerStyle' },
					exporterPdfFooter: function (currentPage, pageCount) {
						return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
					},
					exporterPdfCustomFormatter: function (docDefinition) {
						docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
						docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
						return docDefinition;
					},
					exporterPdfOrientation: 'portrait',
					exporterPdfPageSize: 'LETTER',
					exporterPdfMaxGridWidth: 500,
					exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
					exporterExcelFilename: 'Fatura.xlsx',
					exporterExcelSheetName: 'Sheet1',
					exporterExcelCustomFormatters: function (grid, workbook, docDefinition) {
						var stylesheet = workbook.getStyleSheet();
						var stdStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri'
						});
						var boldStyle = stylesheet.createFontStyle({
							size: 9, fontName: 'Calibri', bold: true
						});
						var aFormatDefn = {
							"font": boldStyle.id,
							"alignment": { "wrapText": true }
						};
						var formatter = stylesheet.createFormat(aFormatDefn);
						$scope.formatters['bold'] = formatter;
						var dateFormatter = stylesheet.createSimpleFormatter('date');
						$scope.formatters['date'] = dateFormatter;
						aFormatDefn = {
							"font": stdStyle.id,
							"fill": { "type": "pattern", "patternType": "solid", "fgColor": "FFFFC7CE" },
							"alignment": { "wrapText": true }
						};
						var singleDefn = {
							font: stdStyle.id,
							format: '#,##0.0'
						};
						formatter = stylesheet.createFormat(aFormatDefn);
						$scope.formatters['red'] = formatter;
						Object.assign(docDefinition.styles, $scope.formatters);
						return docDefinition;
					},
					exporterColumnScaleFactor: 4.5,
					exporterFieldApplyFilters: true,
					columnDefs: [
						{ name: 'İl', field: 'city' },
						{ name: 'İlçe', field: 'district' },
						{ 
              name: 'Desi', 
              cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.min_weight}} - {{row.entity.max_weight}}</div>',
              width: 100
            },
						{
							name: 'Firma 1', field: 'company_1'
						},
						{ name: 'Kapasite 1', field: 'company_1_capacity', type: 'number' },
						{
							name: 'Firma 2', field: 'company_2'
						},
						{ name: 'Kapasite 2', field: 'company_2_capacity', type: 'number' },
						{
							name: 'Firma 3', field: 'company_3'
						},
						{ name: 'Kapasite 3', field: 'company_3_capacity', type: 'number' },
					],
					data: data.allocation.rows
				};
        console.log( $rootScope.gridOptionsComplex);
        $rootScope.gridOptionsComplex.onRegisterApi = function (gridApi) {
					$scope.gridApi = gridApi;
				};
      });
    }

    $scope.$on('new_data', function () {
      $('#allocationModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      Allocation.new().success(function (data, status, headers, config) {
        $scope.formData = data;
        $scope.allocation = {
          name: 'ALLOKASYON-TABLO-',
          status: 'active',
          file: null
        };
      });
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        Allocation.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post($scope.translate('Silindi'));
          $scope.all();
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.allocation.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      Allocation.save($scope.allocation).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      Allocation.update($scope.allocation).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

    $scope.addPrice = function (workType, type) {
      $scope.allocation.prices.push({
        min_weight: null,
        type: 'weight',
        work_type: workType,
        price_type: type
      });
    }

    $scope.removePrice = function (item) {
      var index = $scope.allocation.prices.findIndex(function (elm) {
        return elm.$$hashKey == item.$$hashKey
      })
      $scope.allocation.prices.splice(index, 1);
    }

    var file = $scope.file = new FileUploader({
      url: '/api/upload-allocation',
      headers: { 'X-CSRF-TOKEN': CSRF_TOKEN },
      autoUpload: true
    });

    file.filters.push({
      name: 'formatFilter',
      fn: function (item /*{File|FileLikeObject}*/, options) {
        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|xlsx|xls|vnd.openxmlformats-officedocument.spreadsheetml.sheet|vnd.ms-excel|'.indexOf(type) !== -1;
      }
    });

    file.onAfterAddingFile = function (fileItem) {
      $scope.proccessProfile = true;
      $scope.pending = true;
    };

    file.onCompleteItem = function (item, response, status, headers) {
      $scope.proccessProfile = false;
      $scope.allocation.file = response.file;
      if (response.status == 'success')
        $scope.pending = false;
    }


  }
]);