bovoApp.
  factory('AllocationAssign', ['$http', function ($http) {
    return {
      all: function () {
        return $http.get('api/allocation-assigns');
      },
      get: function (id) {
        return $http.get('api/allocation-assign/' + id);
      },
      new: function (id) {
        return $http.get('api/new-allocation-assign');
      },
      delete: function (id) {
        return $http.delete('api/delete-allocation-assign/' + id);
      },
      save: function (data) {
        return $http.post('api/save-allocation-assign', data);
      },
      update: function (data) {
        return $http.post('api/update-allocation-assign', data);
      },
      getGroupCustomers: function (data) {
        return $http.post('api/allocation-group-customers', data);
      },
      checkCustomer: function (data) {
        return $http.post('api/allocation-assign-check-customer', data);
      }
    };
  }]);
