bovoApp.controller('AllocationAssignController', ['$scope', 'AllocationAssign', '$location', '$routeParams', '$filter', '$translate',
  function ($scope, AllocationAssign, $location, $routeParams, $filter, $translate) {
    $scope.deleted = [];

    $scope.all = function () {
      AllocationAssign.all().success(function (data, status, headers, config) {
        $scope.data = data;
      });
    }

    $scope.openDetail = function (item) {
      AllocationAssign.get(item.id).success(function (data, status, headers, config) {
        $('#allocationAssignModal').modal('show');
        $scope.allocationAssign = data.allocationAssign;
        $scope.customers = data.allocationAssign.customers.map(customer => customer.company_customer_id);
        $scope.formData = data;
      });
    }

    $scope.$on('new_data', function () {
      $('#allocationAssignModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      AllocationAssign.new().success(function (data, status, headers, config) {
        $scope.formData = data;
        $scope.allocationAssign = {
        };
        $scope.customers = [];
      });
    }

    $scope.getGroupCustomers = function (customer_group, set = false) {
      AllocationAssign.getGroupCustomers({ customer_group: customer_group.id }).success(function (data, status, headers, config) {
        $scope.formData.customers = data.customers;
      });
    }

    $scope.checkCustomer = function (customer_id, index) {
      AllocationAssign.checkCustomer({ customer_id: customer_id }).success(function (data, status, headers, config) {
        if (data.assing_status) {
          Messenger().post({
            message: data.customer.customer_name + ' müşterisi zaten ' + data.table.name + ' tablosunda atanmış durumda yeniden atanamaz',
            type: "error"
          });
          $scope.customers.splice($scope.customers.indexOf(index), 1);
        }
      });
    }

    $scope.bulkAssignTable = function (customer_group) {
      $scope.formData.customers.forEach(element => {
        $scope.customers.push(element.id);
        $scope.checkCustomer(element.id, $scope.customers.length - 1);
      });
    }

    $scope.addItem = function () {
      console.log($scope.allocationAssign.customers)
      $scope.customers.push(null);
    }

    $scope.removeItem = function (index) {
      $scope.customers.splice(index, 1);
    }

    $scope.delete = function (type) {
      if (type == 'set') {
        $('#delete').modal('show');
      } else if (type == 'delete') {
        AllocationAssign.delete($scope.deleted).success(function (data, status, headers, config) {
          Messenger().post('Silindi');
          $scope.all()
          $scope.deleted = [];
          $('#delete').modal('hide');
        }).error(function (data) {
          Messenger().post({
            message: data.message,
            type: "error"
          });
        });
      }
    }

    $scope.postForm = function (user) {
      if ($scope.allocationAssign.id) {
        $scope.update();
      } else {
        $scope.save();
      }
    }

    $scope.save = function () {
      $scope.allocationAssign.customers = $scope.customers;
      AllocationAssign.save($scope.allocationAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      $scope.allocationAssign.customers = $scope.customers;
      AllocationAssign.update($scope.allocationAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);