bovoApp.controller('AllocationControlController', ['$scope', 'AllocationControl', '$location', '$routeParams', '$filter', '$translate', '$rootScope',
  function ($scope, AllocationControl, $location, $routeParams, $filter, $translate, $rootScope) {
    $scope.deleted = [];

    $scope.all = function () {
      AllocationControl.all().success(function (data, status, headers, config) {
        $scope.data = data;
        $rootScope.gridOptionsComplex = {
          enableFiltering: true,
          showGridFooter: true,
          showColumnFooter: true,
          paginationPageSizes: [500, 1000, 2000],
          paginationPageSize: 500,
          enableGridMenu: true,
          enableSelectAll: true,
          exporterCsvFilename: 'KararListesi.csv',
          exporterPdfDefaultStyle: { fontSize: 9 },
          exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
          exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
          exporterPdfHeader: { text: "My Header", style: 'headerStyle' },
          exporterPdfFooter: function (currentPage, pageCount) {
            return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
          },
          exporterPdfCustomFormatter: function (docDefinition) {
            docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
            docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
            return docDefinition;
          },
          exporterPdfOrientation: 'portrait',
          exporterPdfPageSize: 'LETTER',
          exporterPdfMaxGridWidth: 500,
          exporterCsvLinkElement: angular.element(document.querySelectorAll(".custom-csv-link-location")),
          exporterExcelFilename: 'Fatura.xlsx',
          exporterExcelSheetName: 'Sheet1',
          exporterExcelCustomFormatters: function (grid, workbook, docDefinition) {
            var stylesheet = workbook.getStyleSheet();
            var stdStyle = stylesheet.createFontStyle({
              size: 9, fontName: 'Calibri'
            });
            var boldStyle = stylesheet.createFontStyle({
              size: 9, fontName: 'Calibri', bold: true
            });
            var aFormatDefn = {
              "font": boldStyle.id,
              "alignment": { "wrapText": true }
            };
            var formatter = stylesheet.createFormat(aFormatDefn);
            $scope.formatters['bold'] = formatter;
            var dateFormatter = stylesheet.createSimpleFormatter('date');
            $scope.formatters['date'] = dateFormatter;
            aFormatDefn = {
              "font": stdStyle.id,
              "fill": { "type": "pattern", "patternType": "solid", "fgColor": "FFFFC7CE" },
              "alignment": { "wrapText": true }
            };
            var singleDefn = {
              font: stdStyle.id,
              format: '#,##0.0'
            };
            formatter = stylesheet.createFormat(aFormatDefn);
            $scope.formatters['red'] = formatter;
            Object.assign(docDefinition.styles, $scope.formatters);
            return docDefinition;
          },
          exporterColumnScaleFactor: 4.5,
          exporterFieldApplyFilters: true,
          columnDefs: [
            { name: 'ID', field: 'decision_id' },
            { name: 'Plaka', field: 'plate' },
            { name: 'İl', field: 'city' },
            { name: 'İlçe', field: 'district' },
            {
              name: 'Firma 1', field: 'company_1',
              editableCellTemplate: 'ui-grid/dropdownEditor',
              editDropdownValueLabel: 'value',
              editDropdownOptionsArray: $scope.cargoFirms
            },
            { name: 'Kapasite 1', field: 'capacity_1', type: 'number' },
            {
              name: 'Firma 2', field: 'company_2',
              editableCellTemplate: 'ui-grid/dropdownEditor',
              editDropdownValueLabel: 'value',
              editDropdownOptionsArray: $scope.cargoFirms
            },
            { name: 'Kapasite 2', field: 'capacity_2', type: 'number' },
            {
              name: 'Firma 3', field: 'company_3',
              editableCellTemplate: 'ui-grid/dropdownEditor',
              editDropdownValueLabel: 'value',
              editDropdownOptionsArray: $scope.cargoFirms
            },
            { name: 'Kapasite 3', field: 'capacity_3', type: 'number' },
            {
              name: 'Firma 4', field: 'company_4',
              editableCellTemplate: 'ui-grid/dropdownEditor',
              editDropdownValueLabel: 'value',
              editDropdownOptionsArray: $scope.cargoFirms
            },
            { name: 'Kapasite 4', field: 'capacity_4', type: 'number' },
            // { name: 'Oluşuran', field: 'full_name' }
          ],
          data: data.data
        };

        $rootScope.gridOptionsComplex.onRegisterApi = function (gridApi) {
          $scope.gridApi = gridApi;

          gridApi.selection.on.rowSelectionChanged($scope, function (row) {
            if (row.isSelected) {
              $scope.selectedItems.push(row.entity.id)
            } else {
              var index = $scope.selectedItems.indexOf(row.entity.id);
              if (index > -1) {
                $scope.selectedItems.splice(index, 1);
              }
            }
          });

          gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
            rows.forEach(element => {
              if (element.isSelected) {
                $scope.selectedItems.push(element.entity.id)
              } else {
                var index = $scope.selectedItems.indexOf(element.entity.id);
                if (index > -1) {
                  $scope.selectedItems.splice(index, 1);
                }
              }
            });
          });
        };

      });
    }

    $scope.openDetail = function (item) {
      AllocationControl.get(item.id).success(function (data, status, headers, config) {
        $('#allocationAssignModal').modal('show');
        $scope.allocationAssign = data.allocationAssign;
        $scope.customers = data.allocationAssign.customers.map(customer => customer.company_customer_id);
        $scope.formData = data;
      });
    }

    $scope.$on('new_data', function () {
      $('#allocationAssignModal').modal('show');
      $scope.new();
    }, true);

    $scope.new = function () {
      AllocationControl.new().success(function (data, status, headers, config) {
        $scope.formData = data;
        $scope.allocationAssign = {
        };
        $scope.customers = [];
      });
    }

    $scope.save = function () {
      $scope.allocationAssign.customers = $scope.customers;
      AllocationControl.save($scope.allocationAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.update = function () {
      $scope.allocationAssign.customers = $scope.customers;
      AllocationControl.update($scope.allocationAssign).success(function (data, status, headers, config) {
        $scope.data = data;
        Messenger().post('Başarılı');
        $('#allocationAssignModal').modal('hide');
        $scope.all();
      }).error(function (data) {
        Messenger().post({
          message: data.message,
          type: "error"
        });
      });
    }

    $scope.toggleSelection = function toggleSelection(id) {
      var idx = $scope.deleted.indexOf(id);
      if (idx > -1) {
        $scope.deleted.splice(idx, 1);
      } else {
        $scope.deleted.push(id);
      }
    };

  }
]);