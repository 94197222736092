bovoApp.
  factory('Allocation', ['$http', function ($http) {
    return {
      all: function () {
        return $http.get('api/allocations');
      },
      get: function (id) {
        return $http.get('api/allocation/' + id);
      },
      new: function (id) {
        return $http.get('api/new-allocation');
      },
      delete: function (id) {
        return $http.delete('api/delete-allocation/' + id);
      },
      save: function (data) {
        return $http.post('api/save-allocation', data);
      },
      update: function (data) {
        return $http.post('api/update-allocation', data);
      },
      getTables: function (data) {
        return $http.post('api/allocation-tables', data);
      },
    };
  }]);